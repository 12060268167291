import React from "react";
import "../Styles/contact-us.css";
import Header from "./Header";
import HeaderDrawer from "./HeaderDrawer";
import { useDocumentTitle } from "../Hooks/changeTitleHook";

function ContactUs() {
  useDocumentTitle("Information to contact Huming's Team");
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "Contact us at huming with any query, curiosity, doubt, complaint. We at Huming InfoTech Private Limited."
    );
  return (
    <div>
      <Header inFocus={1} strokeColor={"black"} screenName={"ContactUs"} />
      <HeaderDrawer inFocus={1} screenName={"ContactUs"} />
      <div className="orangeBase">
        <div className="baseShadowCU">
          <div
            style={{
              flex: 6,
              backgroundColor: "blanchedalmond",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ backgroundColor: "blanchedalmond", flex: 5 }}></div>
            {/* contact us text */}
            <div
              style={{
                // backgroundColor: "blueviolet",
                flex: 15,

                textAlign: "center",
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                fontWeight: "400",
              }}
            >
              <h1 style={{ lineHeight: "1.5", fontSize: 30 }}>Contact Us</h1>
            </div>
            {/* suggestion text */}

            <div
              style={{
                // backgroundColor: "yellowgreen",
                flex: 5,
                lineHeight: "1.8",

                fontWeight: "normal",
                color: "#666666",
                textAlign: "center",
              }}
            >
              <h2 style={{ fontSize: 14 }}>We're open for any suggestion...</h2>
            </div>
            {/* Address , email, etc */}

            <div
              style={{
                backgroundColor: "blanchedalmond",
                flex: 35,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  // backgroundColor: "green",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // backgroundColor: "black",
                    flex: 1,
                    lineHeight: "1.5",
                    fontSize: 13,
                    textTransform: "capitalize",
                    fontWeight: "600",
                    color: "rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <p style={{ textAlign: "center" }}>Address</p>
                </div>
                <div
                  style={{
                    // backgroundColor: "oldlace",
                    flex: 1,
                    lineHeight: "1.8",
                    fontSize: 14,
                    fontWeight: "normal",
                    color: "#666666",
                  }}
                >
                  <p style={{ textAlign: "center" }}>
                    1, Keshav Nagar, University Road, Nagada Restaurent's
                    street,Udaipur, Rajasthan, India 313001
                  </p>
                </div>
              </div>
              <div
                style={{
                  // backgroundColor: "burlywood",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // backgroundColor: "yellowgreen",
                    flex: 1,
                    fontSize: 13,
                    textTransform: "capitalize",
                    fontWeight: "600",
                    color: "rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <p style={{ textAlign: "center" }}>Email Us At</p>
                </div>
                <div
                  style={{
                    // backgroundColor: "brown",
                    flex: 2,
                    lineHeight: "1.8",
                    fontSize: 14,
                    fontWeight: "normal",
                    color: "#666666",
                  }}
                >
                  <p style={{ textAlign: "center" }}>Jainsam.2706@gmail.com</p>
                </div>
              </div>
            </div>
            {/* Social media */}

            <div
              style={{
                // backgroundColor: "blueviolet",
                backgroundColor: "cadetblue",
                flex: 30,
                // flex: 15,
                lineHeight: "1.5",
                fontSize: 30,
                fontWeight: "400",
                textAlign: "center",
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              WE CARE...
            </div>
            <div style={{ backgroundColor: "cadetblue", flex: 10 }}></div>
          </div>
          <div
            style={{
              flex: 4,
              backgroundColor: "yellow",
              backgroundImage: `url(${require("../Assets/Images/photocolage.webp")})`,
              backgroundSize: "contain",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
